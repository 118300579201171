$danger-color:hsla(0, 100%, 50%, 0.849);
$success-color:rgb(19, 160, 6);
$bg-color: rgb(214, 218, 226);
// $bg-light:#eceff8;
$bg-light:#d7dff7;
$primary-color:black;
$secondary-color:white;
$card-header:#0822e17d;
$bg-course:rgb(216, 216, 216);
$btn-seen:#372791;
$btn-seen-hover:#5c43e9;
$table-num:#9b05717d;
$add-on:rgb(247, 181, 82);
$modifications:#1a5de3;
$cook-process:rgb(190,253,234);
$cook-process-hover:#09d99a;
$cook-process-icon:rgb(17,188,134);
$btn-search: #9b05717d;
$btn-selected:rgb(54,163,236);
$btn-search-hover: #0822e17d;
$date-picker-border: rgb(220, 224, 232);
$bg-filter: rgb(236,239,248);
$bg-timesales:rgb(174,225,208);
$footer:rgb(220,224,232);
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&family=Source+Sans+Pro:wght@300;400;600&display=swap');
// $card-header:rgb(170, 0, 255);
// $btn-seen:rgb(237, 40, 144);
